import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { SNACKBAR_SUCCESS, SNACKBAR_FAILURE } from '../../config/snackbar.config';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly translocoService: TranslocoService,
  ) {}

  /**
   * Show a success snackbar with translated message
   * @param messageKey Translation key for the message
   * @param params Optional parameters for translation interpolation
   */
  success(messageKey: string, params?: Record<string, unknown>) {
    const message = this.translocoService.translate(messageKey, params);
    this.snackBar.open(message, this.translocoService.translate(SNACKBAR_SUCCESS));
  }

  /**
   * Show an error snackbar with translated message
   * @param messageKey Translation key for the message
   * @param params Optional parameters for translation interpolation
   */
  error(messageKey: string, params?: Record<string, unknown>) {
    const message = this.translocoService.translate(messageKey, params);
    this.snackBar.open(message, this.translocoService.translate(SNACKBAR_FAILURE));
  }

  /**
   * Show a custom snackbar with translated message and action
   * @param messageKey Translation key for the message
   * @param action Custom action label
   * @param params Optional parameters for translation interpolation
   */
  show(messageKey: string, action?: string, params?: Record<string, unknown>) {
    const message = this.translocoService.translate(messageKey, params);
    this.snackBar.open(message, action);
  }
}

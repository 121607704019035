import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  COLLECTION_DOMAIN_REGEX,
  FORM_VALIDATORS,
} from '../../../../config/forms-validation.config';
import { CONNECT_ACTION } from './../../../../theme/header/header.component.model';

import { AuthService } from 'apps/arsultima/src/app/core/authentication/services/auth/auth.service';
import { ArsultimaErrorTypes } from '../../../../config/errors.config';
import { EmailSentComponent } from '../email-sent/email-sent.component';
import { PasswordMatchValidator } from './../../../../shared/validators/password-confirm.validator';
import { LoginFormType } from './login-form.component.model';
import { SnackbarService } from '../../../../core/services/snackbar.service';

export enum PasswordTypes {
  'signin',
  'signup',
  'signupConfirm',
  'signupNormalAndConfirm',
}

@Component({
  selector: 'arsultima-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  title = 'Signin to your Arsultima Account';

  loginForm: FormGroup;
  resetForm: FormGroup;
  signupForm: FormGroup;

  formToShow = LoginFormType.SIGNIN;

  passwordTypes = PasswordTypes;

  private _showPassword: Record<PasswordTypes, boolean> = {
    [PasswordTypes.signin]: false,
    [PasswordTypes.signup]: false,
    [PasswordTypes.signupConfirm]: false,
    [PasswordTypes.signupNormalAndConfirm]: false,
  };

  constructor(
    private readonly fb: FormBuilder,
    private readonly authService: AuthService,
    private readonly dialogRef: MatDialogRef<LoginFormComponent>,
    private readonly dialog: MatDialog,
    private readonly snackbarService: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: { action: CONNECT_ACTION },
  ) {
    this.title = 'title.login';

    switch (data.action) {
      case CONNECT_ACTION.SIGNIN:
        this.showSignin();
        break;
      case CONNECT_ACTION.SIGNUP:
        this.showSignup();
        break;
      default:
        this.showSignin();
        break;
    }
  }

  get isSigninForm() {
    return this.formToShow === LoginFormType.SIGNIN;
  }

  get isSignupForm() {
    return this.formToShow === LoginFormType.SIGNUP;
  }

  get isResetForm() {
    return this.formToShow === LoginFormType.RESET;
  }

  ngOnInit(): void {
    this.setLoginForm();
  }

  getPasswordType(passwordType: PasswordTypes): 'text' | 'password' {
    return this._showPassword[passwordType] ? 'text' : 'password';
  }

  getPasswordIcon(passwordType: PasswordTypes): string {
    return this._showPassword[passwordType] ? 'visibility' : 'visibility_off';
  }

  togglePasswordVisibility(passwordType: PasswordTypes, event: Event) {
    event.preventDefault();
    this._showPassword[passwordType] = !this._showPassword[passwordType];
  }

  showPassword(passwordType: PasswordTypes, event: Event) {
    event.preventDefault();
    this._showPassword[passwordType] = true;
  }

  hidePassword(passwordType: PasswordTypes, event: Event) {
    event.preventDefault();
    this._showPassword[passwordType] = false;
  }

  goback() {
    console.log('Go Back to previous page');
  }

  submit() {
    const { email, password } = this.loginForm.value;
    this.authService.authenticate({ email, password }).subscribe(response => {
      const { data }: any = response;

      // AS the error and success response are not the same
      // We just set as any... Would be better to fix the API later
      // EXAMPLE of error response
      //   {
      //     "data":{
      //        "err":{
      //           "id":"loginFailure",
      //           "value":"id_wrong"
      //        },
      //        "data":[

      //        ],
      //        "meta":{
      //           "from":"DBLogins.php:retour_login:134"
      //        }
      //     }
      //  }
      // EXAMPLE of success response
      //   {
      //     "data":{
      //        "db":"lind",
      //        "token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzaXRlIjoibGluZCIsInVzZXJpZCI6ImQwYTc4NGEwMjg0MjdkYjY0MzQyMDY2ZWRjMGE5MWEwIn0.ly1W6Z2S-yrheQiaURlUNQeuFzN7oqa8Jq9aKmfOryw"
      //     }
      //  }

      if (data.err) {
        this.showNoAccountFound();
      } else {
        // The redirect is handled in the service
        this.dialogRef.close();
      }
    });
  }

  resetSubmit() {
    this.authService.forgotPassword(this.resetForm.value).subscribe(
      response => {
        const { data }: any = response;
        if (data.err) {
          this.showNoAccountFound();
        } else {
          this.dialogRef.close();
          const emailSentDialogRef = this.dialog.open(EmailSentComponent);
          console.log(emailSentDialogRef);
        }
      },
      _err => {
        this.showNoAccountFound();
      },
    );
  }

  signupSubmit() {
    const { value } = this.signupForm;
    this.authService.signup(value).subscribe(response => {
      const { data }: any = response;
      if (data) {
        this.snackbarService.success('connect.emailValidationSent');
        this.dialogRef.close();
      }
    });
  }

  showSignin() {
    this.formToShow = LoginFormType.SIGNIN;
    this.getPopupTitle();
    this.setLoginForm();
  }

  showPasswordReset() {
    this.formToShow = LoginFormType.RESET;
    this.getPopupTitle();
    this.setResetForm();
  }

  showSignup() {
    this.formToShow = LoginFormType.SIGNUP;
    this.getPopupTitle();
    this.setSignupForm();
  }

  // debugForm() {
  //   console.log('SIGNUP FORM', this.signupForm)
  // }

  private getPopupTitle() {
    if (this.isResetForm) {
      this.title = 'connect.resetTitle';
    } else if (this.isSignupForm) {
      this.title = 'connect.signupTitle';
    } else {
      this.title = 'connect.signinTitle';
    }
  }

  private showNoAccountFound() {
    this.snackbarService.error('error.' + ArsultimaErrorTypes.NO_ACCOUNT_FOUND);
  }

  private setLoginForm() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, ...FORM_VALIDATORS.email]],
      password: ['', [Validators.required]],
    });
  }

  private setResetForm() {
    this.resetForm = this.fb.group({
      email: ['', [Validators.required, ...FORM_VALIDATORS.email]],
    });
  }

  private setSignupForm() {
    this.signupForm = this.fb.group(
      {
        email: ['', [Validators.required, ...FORM_VALIDATORS.email]],
        password: ['', [Validators.required, ...FORM_VALIDATORS.password]],
        passwordConfirm: ['', [Validators.required, ...FORM_VALIDATORS.password]],
        subdomain: [
          '',
          [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(32),
            Validators.pattern(COLLECTION_DOMAIN_REGEX),
          ],
        ],
      },
      {
        validator: PasswordMatchValidator, // Add the custom validator to the form
      },
    );
    // this.signupForm.valueChanges.subscribe(_value => {
    //   console.log('Signup form value', this.signupForm);
    // });
  }
}

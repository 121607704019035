import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'arsultima-reset-button',
  templateUrl: './reset-button.component.html',
  styleUrls: ['./reset-button.component.scss'],
})
export class ResetButtonComponent implements OnInit {
  @Input() label = 'buttons.reset';

  constructor() {}

  ngOnInit(): void {}
}

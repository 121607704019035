import {
  API_ERRORS_ID,
  ARSULTIMA_ERRORS_API_ID_TO_FRONT_ID,
  SHOW_MESSAGES_ONLY_FOR_KNOWN_API_ERRORS,
  ArsultimaErrorTypes,
} from './../../config/errors.config';

import { Injectable } from '@angular/core';
import { SnackbarService } from '../services/snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorsService {
  constructor(private readonly snackbarService: SnackbarService) {}

  showDefaultError(apiErrorId: API_ERRORS_ID = API_ERRORS_ID.VOID) {
    this.snackbarService.error('error.' + ArsultimaErrorTypes.DEFAULT_ERROR, { apiErrorId });
  }

  showFrontDefinedError(apiErrorId: API_ERRORS_ID) {
    const frontId = ARSULTIMA_ERRORS_API_ID_TO_FRONT_ID[apiErrorId];
    this.snackbarService.error('error.' + (frontId || ArsultimaErrorTypes.DEFAULT_ERROR), {
      apiErrorId,
    });
  }

  showError(apiErrorId: API_ERRORS_ID) {
    if (this.isErrorKnown(apiErrorId)) {
      this.showFrontDefinedError(apiErrorId);
    } else if (!SHOW_MESSAGES_ONLY_FOR_KNOWN_API_ERRORS) {
      this.showDefaultError(apiErrorId);
    }
  }

  private isErrorKnown(apiErrorId: API_ERRORS_ID): boolean {
    console.log('Error from API with ID ' + apiErrorId);
    return !!ARSULTIMA_ERRORS_API_ID_TO_FRONT_ID[apiErrorId];
  }
}

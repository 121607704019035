<!-- DESKTOP VERSION -->
<nav>
  <div
    class="container-fluid header-wrapper d-flex p-xs align-items-center align-items-{{
      limitMobile
    }}-end justify-content-between "
  >
    <!-- Left aligned -->
    <div class="left flex align-items-center flex-grow-2">
      <arsultima-logo
        [path]="path.logo"
        [logoUrl]="logoUrlDynamic"
        [isEditable]="false"
        [client]="client"
        (logoUploaded)="setLogoUrlDynamic()"
      ></arsultima-logo>
      <!-- we disable temporarly the logo [isEditable]="userConnexion?.canEdit" -->

      <div class="d-none d-{{ limitMobile }}-block flex-grow-2" *ngIf="desktopMenu">
        <ng-container *ngTemplateOutlet="menuDesktop"></ng-container>
      </div>
    </div>

    <!-- Right aligned -->
    <div class="right">
      <div class="d-none d-{{ limitMobile }}-block" *ngIf="desktopMenu">
        <ng-container *ngTemplateOutlet="login; context: { mobile: false }"></ng-container>
      </div>

      <!-- <arsultima-user-menu [client]="client"></arsultima-user-menu> -->
      <ng-container *ngIf="!client?.fields?.nom_cli">
        <arsultima-lang-switcher
          [lang]="lang"
          class="m-r-md m-l-md d-none d-{{ limitMobile }}-block"
        ></arsultima-lang-switcher>
      </ng-container>

      <div
        matRipple
        [matRippleCentered]="true"
        class="button-icon active align-self-center m-md d-block d-{{ limitMobile }}-none"
      >
        <mat-icon class="fa-2x" fontSet="fas" fontIcon="fa-bars" (click)="toggleMenu()"></mat-icon>
      </div>
    </div>
  </div>
</nav>

<!-- MOBILE VERSION -->
<div class="mobile-menu">
  <ng-container *ngIf="debug">
    <div>isConnected {{ isConnected }}</div>
    <div>Page data DB {{ userConnexion?.db }}</div>
    <div>User DB {{ userConnexion?.connectedUserDb }}</div>
    <div>isSameDbAsConnected {{ isSameDbAsConnected }}</div>
    <div>user connexion {{ userConnexion | json }}</div>
  </ng-container>

  <ng-container *ngIf="mobileMenuOpened">
    <ng-container *ngTemplateOutlet="menuMobile"></ng-container>
  </ng-container>
</div>

<!-- TEMPLATES -->
<ng-template #menuDesktop>
  <div class="header-menu">
    <ul class="arsu-menu text-header desktop m-r-lg" (click)="hideMenuOnMobile(false)">
      <ul class="flex-justify-start flex-grow-2">
        <ng-container *ngTemplateOutlet="menuItems"></ng-container>
      </ul>
      <ul class="flex-justify-start">
        <ng-container *ngTemplateOutlet="loginItems"></ng-container>
      </ul>
    </ul>
  </div>
</ng-template>

<ng-template #menuMobile let-mobile="mobile">
  <div class="header-menu">
    <ul class="arsu-menu text-header mobile" (click)="hideMenuOnMobile(true)">
      <ng-container *ngTemplateOutlet="menuItems"></ng-container>
      <ng-container *ngTemplateOutlet="loginItems"></ng-container>
    </ul>
  </div>
</ng-template>

<ng-template #separator>
  <li class="menu-separator d-xs-none d-sm-none d-md-none d-lg-block">〜</li>
</ng-template>

<ng-template #menuItems>
  <li routerLinkActive="active" class="nav-item hidden-tablet">
    <a [routerLink]="path.home" class="nav-link">{{ 'menu.home' | transloco }}</a>
  </li>
  <!-- <ng-container *ngIf="isArsultima">
    <ng-container *ngTemplateOutlet="separator"></ng-container>
    <li routerLinkActive="active" class="nav-item">
      <a [routerLink]="path.discover" class="nav-link">{{ 'menu.discover' | transloco }}</a>
    </li>
  </ng-container> -->
  <ng-container *ngTemplateOutlet="separator"></ng-container>
  <li routerLinkActive="active" class="nav-item">
    <a [routerLink]="path.exhibit" class="nav-link">{{ 'menu.exhibit' | transloco }}</a>
  </li>

  <!-- Connected private section-->
  <ng-container *ngIf="isConnected && isSameDbAsConnected">
    <ng-container *ngTemplateOutlet="separator"></ng-container>
    <li routerLinkActive="active" class="nav-item">
      <a [routerLink]="path.database" class="nav-link">{{ 'menu.database' | transloco }}</a>
    </li>
    <ng-container *ngTemplateOutlet="separator"></ng-container>
    <li routerLinkActive="active" class="nav-item">
      <a [routerLink]="path.stats" class="nav-link">{{ 'menu.stats' | transloco }}</a>
    </li>
    <ng-container *ngTemplateOutlet="separator"></ng-container>
    <li routerLinkActive="active" class="nav-item">
      <a [routerLink]="path.settings" class="nav-link">{{ 'menu.settings' | transloco }}</a>
    </li>
  </ng-container>
  <!-- End of connected private section -->

  <ng-container *ngIf="(displayPreferences$ | async)?.contact">
    <ng-container *ngTemplateOutlet="separator"></ng-container>
    <li routerLinkActive="active" class="nav-item">
      <a [routerLink]="path.contact" class="nav-link">{{ 'menu.contact' | transloco }}</a>
    </li>
  </ng-container>
</ng-template>

<ng-template #loginItems>
  <ng-container *ngIf="isConnected && !isNotOnConnectedSite">
    <li [tooltip]="'menu.help' | transloco" class="nav-item">
      <a
        target="_blank"
        href="https://arsu.arsultima.com/ATpics/Manuel.pdf"
        class="flex-justify-center nav-link"
      >
        <mat-icon class="m-r-xxs color-gold font-size-bigger">info</mat-icon>
        <!-- {{ 'menu.help' | transloco }} -->
      </a>
    </li>
    <ng-container *ngTemplateOutlet="separator"></ng-container>
  </ng-container>

  <!-- Link to Arsultima corporate site -->
  <ng-container *ngIf="showArsultimaButton">
    <li routerLinkActive="active" class="nav-item">
      <a href="/arsultima/home" class="nav-link">Arsultima</a>
    </li>
    <ng-container *ngTemplateOutlet="separator"></ng-container>
  </ng-container>

  <ng-container *ngIf="!isConnected">
    <li routerLinkActive="active" class="nav-item">
      <a class="nav-link" (click)="openLoginDialog(signinAction)">
        {{ 'menu.signin' | transloco }}
      </a>
    </li>

    <li
      routerLinkActive="active"
      class="nav-item m-l-md m-md-mobile m-t-lg-mobile"
      [ngClass]="{ 'button-item': isNotOnConnectedSite }"
    >
      <a class="nav-link" (click)="openLoginDialog(signupAction)">
        {{ 'menu.signup' | transloco }}
      </a>
    </li>
  </ng-container>

  <ng-container *ngIf="isConnected && isNotOnConnectedSite">
    <!-- IF the user if on another site or on Arsultima site -->
    <li
      routerLinkActive="active"
      class="nav-item m-l-md"
      [ngClass]="{ 'button-item': isNotOnConnectedSite }"
    >
      <a class="nav-link" (click)="goToConnectedUserSite()">
        {{ 'menu.myAccount' | transloco }}
      </a>
    </li>
  </ng-container>

  <ng-container *ngIf="isConnected && !isNotOnConnectedSite">
    <li routerLinkActive="active" class="nav-item">
      <a class="nav-link" (click)="signout()">{{ 'menu.signout' | transloco }}</a>
    </li>
  </ng-container>
</ng-template>
